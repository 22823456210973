import Divider from '@/components/Divider'
import Logo from '@/components/Logo'
import { Actions, Events } from '@/enums/eventos'
import { trackEvent } from '@/lib/tracking'
import { RefObject, useEffect, useState } from 'react'

interface Props {
  menuList: {
    ref: RefObject<HTMLDivElement>
    text: string
    eventLabel: string
  }[]
}

const HomeHeader: React.FC<Props> = ({ menuList }) => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false)

  const handleScroll = () => {
    if (document.documentElement.scrollTop > 0) {
      setIsScrolled(true)
    } else {
      setIsScrolled(false)
    }
  }

  useEffect(() => {
    window.onscroll = () => handleScroll()
  }, [])

  const scrollToRef = (
    element: RefObject<HTMLDivElement>,
    eventLabel = '',
    offset = 140,
  ) => {
    if (!element.current) return

    const elementPosition = element.current.getBoundingClientRect().top
    const offsetPosition = elementPosition + window.pageYOffset - offset

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })

    if (eventLabel)
      trackEvent(Events.HOME_HEADER, {
        action: Actions.CLICKED,
        label: eventLabel,
      })
  }

  return (
    <header>
      {/* Header Mobile */}
      <div className="mb-14 block xl:hidden">
        <div className="flex justify-between my-4">
          <div className="mx-auto">
            <Logo />
          </div>
        </div>

        <Divider />
      </div>

      {/* Header Desktop */}
      <div className="h-[119px] w-screen hidden xl:block">
        <div
          className={`flex items-center justify-between px-14 z-50 pt-8 bg-white pb-2 fixed w-inherit transition-shadow duration-200 ${
            isScrolled ? 'shadow-sm' : ''
          }`}
        >
          <div className="flex items-center">
            <div className="shrink-0">
              <Logo width={205} height={48} />
            </div>
            <ul className="list-none flex items-center ml-[60px] 2xl:ml-[144px] gap-12">
              {menuList.map((item) => (
                <li
                  key={item.text}
                  className="whitespace-nowrap text-neutral-500 hover:text-primary-400 text-sm font-medium cursor-pointer hover:border-b-2 hover:border-primary-400 border-b-2 border-transparent -mb-[2px] leading-[36px] transition-all duration-150"
                  onClick={() => scrollToRef(item.ref, item.eventLabel)}
                >
                  {item.text}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}

export default HomeHeader
