import MainHomePage from '@/components/home/MainHomePage'
import OperatorsHomePage from '@/components/home/OperatorsHomePage'
import { IOperadora } from '@/interfaces/operadora'
import { IOperadoraSettings } from '@/interfaces/operadora-settings'
import { getCurrentSettings } from '@/lib/subdomain'
import { OperadoraService } from '@/services/operadora/operadora-service'
import type { GetStaticPaths, GetStaticProps, NextPage } from 'next'

interface Props {
  listaOperadoras: IOperadora[]
  mainHome: boolean
  corretoraEnv: boolean
  currentSettings: IOperadoraSettings
}

const Home: NextPage<Props> = ({
  listaOperadoras,
  mainHome,
  corretoraEnv,
  currentSettings,
}) => {
  return mainHome ? (
    <MainHomePage
      allOperadoras={listaOperadoras}
      corretoraEnv={corretoraEnv}
      currentSettings={currentSettings}
    />
  ) : (
    <OperatorsHomePage currentSettings={currentSettings} />
  )
}

export const getStaticProps: GetStaticProps = async (context) => {
  const subdomain = context?.params?.subdomain || 'main'
  let listaOperadoras = {}
  const currentSettings = getCurrentSettings(subdomain as string)
  let mainHome = false
  let corretoraEnv = false

  if (currentSettings?.isMiddleware && currentSettings?.middlewareId) {
    listaOperadoras = await OperadoraService.getCorretoraOperadoras(
      Number(currentSettings.middlewareId),
    )
    corretoraEnv = true
    mainHome = true
  } else if (subdomain === 'main') {
    listaOperadoras = await OperadoraService.getAllOperadoras()
    mainHome = true
  }
  return {
    props: {
      listaOperadoras,
      mainHome,
      corretoraEnv,
      currentSettings,
    },
  }
}

export const getStaticPaths: GetStaticPaths<{ slug: string }> = async () => {
  return {
    paths: [], //indicates that no page needs be created at build time
    fallback: 'blocking', //indicates the type of fallback
  }
}

export default Home
